"use client";
import { Store } from "@/utils/interfaces/interfaces";
interface AboutUsProps {
  aboutTitle: string;
  aboutContent: string;
  aboutImageUrl: string;
  settings: Partial<Store>;
}
// logout the settings

export default function AboutUs({ aboutTitle, aboutContent, aboutImageUrl, settings }: AboutUsProps) {
  return (
    <section style={{ backgroundColor: settings?.background_color }} className="py-16">
      <div className="container mx-auto px-4 grid md:grid-cols-2 gap-8 items-center">
        <div>
          <h2 style={{ color: settings?.primary_color }} className="text-3xl font-bold mb-4">
            {aboutTitle}
          </h2>
          <p style={{ color: settings?.font_color }}>
            {aboutContent}
          </p>
        </div>
        
        <div className="relative">
          {aboutImageUrl && (
            <img
              src={aboutImageUrl}
              alt="About Us"
              className="rounded-lg shadow-lg w-full h-auto"
            />
          )}
        </div>
      </div>
    </section>
  );
}
