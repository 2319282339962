"use client";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useState } from "react";
import { Store } from "@/utils/interfaces/interfaces";
import { sendEmail } from "@/utils/ses/amazon_ses";

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

interface ContactUsProps {
  settings?: Partial<Store>;
  email?: string;
}

export default function ContactUs({ settings, email }: ContactUsProps) {
  const [formData, setFormData] = useState<ContactFormData>({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    type: "success" | "error" | null;
    message: string;
  }>({ type: null, message: "" });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!settings?.footer_email) {
      setSubmitStatus({
        type: "error",
        message: "No recipient email configured.",
      });
      return;
    }

    const emailDetails = {
      to: settings.footer_email,
      from: "support@yourfirststore.com",
      subject: 'New contact form submission',
      body: formData.message  + " from " + formData.name + " " + formData.email,
    };
    setIsSubmitting(true);

    sendEmail(emailDetails)
      .then(() => {
        console.log("Email sent successfully!");
        setSubmitStatus({
          type: "success",
          message: "Message sent successfully!",
        });
        setFormData({ name: "", email: "", message: "" });
      })
      .catch(() => {
        setSubmitStatus({
          type: "error",
          message: "Failed to send message. Please try again.",
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <section style={{ backgroundColor: settings?.background_color }} className="py-16">
      <div className="container mx-auto px-4 max-w-lg">
        <div className="text-center mb-10">
          <h2 style={{ color: settings?.primary_color }} className="text-3xl font-bold mb-4">
            Contact Us
          </h2>
          <p style={{ color: settings?.font_color }}>
            Have questions? We'd love to hear from you.
          </p>
        </div>

        <div className="space-y-6">
          <div>
            <Label 
              htmlFor="name" 
              style={{ color: settings?.font_color }}
            >
              Name
            </Label>
            <Input
              id="name"
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}

              placeholder="Your name"
              style={{ 
                '--placeholder-color': settings?.tertiary_color 
              } as React.CSSProperties}
              className="mt-1 placeholder:text-[var(--placeholder-color)]"
              suppressHydrationWarning
            />
          </div>

          <div>
            <Label 
              htmlFor="email"
              style={{ color: settings?.font_color }}
            >
              Email
            </Label>
            <Input
              id="email"
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}

              placeholder="your@email.com"
              style={{ 
                '--placeholder-color': settings?.tertiary_color 
              } as React.CSSProperties}
              className="mt-1 placeholder:text-[var(--placeholder-color)]"
              suppressHydrationWarning
            />
          </div>

          <div>
            <Label 
              htmlFor="message"
              style={{ color: settings?.font_color }}
            >
              Message
            </Label>
            <Textarea
              id="message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}

              placeholder="Your message"
              style={{ 
                '--placeholder-color': settings?.tertiary_color 
              } as React.CSSProperties}
              className="mt-1 placeholder:text-[var(--placeholder-color)]"
              rows={5}
              suppressHydrationWarning
            />
          </div>

          {submitStatus.message && (
            <div
              style={{ 
                backgroundColor: submitStatus.type === "success" 
                  ? `${settings?.secondary_color}1A`  // 10% opacity
                  : "#FEE2E2",
                color: submitStatus.type === "success"
                  ? settings?.primary_color
                  : "#B91C1C"
              }}
              className="p-4 rounded-md"
              suppressHydrationWarning
            >
              {submitStatus.message}
            </div>
          )}

          <Button
            type="submit"
            style={{ 
              backgroundColor: settings?.primary_color,
              '--hover-bg': `${settings?.primary_color}E6` // 90% opacity
            } as React.CSSProperties}
            className="w-full hover:bg-[var(--hover-bg)]"
            disabled={isSubmitting}
            suppressHydrationWarning
          >
            {isSubmitting ? "Sending..." : "Send Message"}
          </Button>
        </div>
      </div>
    </section>
  );
}
