'use client';

import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { ProductCard } from './ProductCard';
import { Product, Store } from '@/utils/interfaces/interfaces';
import { Loader2, ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import Link from 'next/link';
import { getProductsByStoreId } from '../products/action';

interface ProductCarouselProps {
  title?: string;
  subtitle?: string;
  domain: string;
  settings?: Partial<Store>;
}

const PREVIEW_PRODUCTS: Product[] = [
  {
    id: 'preview-1',
    name: 'Sample Product 1',
    price: 99.99,
    image_urls: ['https://via.placeholder.com/300'],
    store_id: 'preview',
    description: 'This is a sample product description',
    category: 'Sample Category',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    active: true,
    sku: 'SAMPLE1',
    features: [],
    specifications: {},
    brand: 'Sample Brand',
    stock: 10,
    tags: [],
    status: 'published',
    size: 'M'

  },
  {
    id: 'preview-2',
    name: 'Sample Product 2',
    price: 149.99,
    image_urls: ['https://via.placeholder.com/300'],
    store_id: 'preview',
    description: 'Another sample product description',
    category: 'Sample Category',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    active: true,
    sku: 'SAMPLE2', 
    features: [],
    specifications: {},
    brand: 'Sample Brand',
    stock: 10,
    tags: [],
    status: 'published',
    size:"M",
  },
  {
    id: 'preview-3',
    name: 'Sample Product 3',
    price: 199.99,  
    image_urls: ['https://via.placeholder.com/300'],
    store_id: 'preview',
    description: 'Another sample product description',
    category: 'Sample Category',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    active: true,
    sku: 'SAMPLE3',
    features: [],
    specifications: {},
    brand: 'Sample Brand',
    stock: 10,
    tags: [],
    status: 'published',
    size:"M",
  },
  {
    id: 'preview-4',
    name: 'Sample Product 4',
    price: 249.99,
    image_urls: ['https://via.placeholder.com/300'],
    store_id: 'preview',
    description: 'Another sample product description',
    category: 'Sample Category',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    active: true,
    sku: 'SAMPLE4',
    features: [],
    specifications: {},
    brand: 'Sample Brand',
    stock: 10,
    tags: [],
    status: 'published',
    size:"M",
  }
];

export default function ProductCarousel({ 
  title = "Featured Products",
  subtitle = "Check out our latest products",
  domain,
  settings,
}: ProductCarouselProps) {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isPreview = domain === 'preview';
  
  const productsToShow = 4; // Number of products to show at once
  const canScrollLeft = currentIndex > 0;
  const canScrollRight = currentIndex < products.length - productsToShow;

  const scrollLeft = () => {
    setCurrentIndex(prev => Math.max(0, prev - 1));
  };

  const scrollRight = () => {
    setCurrentIndex(prev => Math.min(products.length - productsToShow, prev + 1));
  };

  useEffect(() => {
    const loadProducts = async () => {
      if (isPreview) {
        setProducts(PREVIEW_PRODUCTS);
        setLoading(false);
        return;
      }

      try {
        const fetchedProducts = await getProductsByStoreId(domain);
        setProducts(fetchedProducts || []);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load products';
        console.error('🎠 Error loading products:', errorMessage);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, [domain, isPreview]);

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        {Loader2 ? (
          <Loader2 
            style={{ color: settings?.primary_color }}
            className="h-8 w-8 animate-spin"
          />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 text-center min-h-[400px] flex items-center justify-center">
        {error}
      </div>
    );
  }

  if (products.length === 0) {
    return (
      <div 
        style={{ color: settings?.tertiary_color }}
        className="min-h-[400px] flex items-center justify-center"
      >
        No products available
      </div>
    );
  }

  return (
    <section style={{ backgroundColor: settings?.background_color }} className="py-12">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-end mb-8">
          <div>
            <h2 
              style={{ color: settings?.font_color }}
              className="text-2xl font-bold mb-2"
            >
              {title}
            </h2>
            <p style={{ color: settings?.tertiary_color }}>
              {subtitle}
            </p>
          </div>
          <Button 
            asChild 
            variant="ghost" 
            style={{ 
              color: settings?.font_color,
              '--hover-color': `${settings?.font_color}90`
            } as React.CSSProperties}
            className="group hover:text-[var(--hover-color)]"
          >
            <Link href={`/domain/${domain}/products`}>
              View All
              <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
            </Link>
          </Button>
        </div>

        <div className="relative">
          <div className="overflow-hidden">
            <div 
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * (100 / productsToShow)}%)` }}
            >
              {products.map((product) => (
                <div 
                  key={product.id}
                  className="w-full min-w-[25%] px-3"
                >
                  <ProductCard 
                    ID={product.id}
                    className="h-full"
                    settings={settings}
                    product={product}
                  >
                    <Link 
                      href={`/domain/${domain}/products/view/${product.id}`}
                      className="block p-4"
                    >
                      {product.image_urls?.[0] && (
                        <div className="aspect-square mb-4">
                          <img
                            src={product.image_urls[0]}
                            alt={product.name}
                            className="w-full h-full object-cover rounded-lg"
                          />
                        </div>
                      )}
                      <h3 
                        style={{ color: settings?.font_color }}
                        className="font-semibold mb-2"
                      >
                        {product.name}
                      </h3>
                      <div className="flex items-center gap-2">
                        <span 
                          style={{ color: settings?.primary_color }}
                          className="text-lg font-bold"
                        >
                          ${product.price.toFixed(2)}
                        </span>
                      </div>
                    </Link>
                  </ProductCard>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Arrows */}
          {canScrollLeft && (
            <button
              onClick={scrollLeft}
              className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors"
              style={{ color: settings?.primary_color }}
            >
              <ChevronLeft className="h-6 w-6" />
            </button>
          )}
          
          {canScrollRight && (
            <button
              onClick={scrollRight}
              className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors"
              style={{ color: settings?.primary_color }}
            >
              <ChevronRight className="h-6 w-6" />
            </button>
          )}
        </div>
      </div>
    </section>
  );
}
