"use client";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ArrowRight, ShoppingBag } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import { Store } from "@/utils/interfaces/interfaces";

interface HeroProps {
  title?: string;
  subtitle?: string;
  ctaText?: string;
  ctaLink?: string;
  imageUrl?: string;
  storeButtonText?: string;
  storeButtonLink?: string;
  className?: string;
  imagePriority?: boolean;
  settings?: Partial<Store>;
}

export default function Hero({
  title = "Welcome to our Store",
  subtitle = "Discover amazing products crafted just for you",
  ctaText = "Shop Now",
  ctaLink = "/products",
  imageUrl = "/default-hero.jpg",
  storeButtonText = "Browse Products",
  storeButtonLink = "/store",
  className,
  imagePriority = true,
  settings,
}: HeroProps) {

  const [isLoaded, setIsLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <section 
      style={{ backgroundColor: settings?.background_color }}
      className="relative py-20 overflow-hidden"
    >
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <div className="space-y-6 text-center md:text-left">
            <h1 
              style={{ color: settings?.primary_color }}
              className="text-4xl md:text-5xl font-bold leading-tight"
            >
              {title}
            </h1>
            
            <p 
              style={{ color: settings?.font_color }}
              className="text-lg md:text-xl"
            >
              {subtitle}
            </p>

            {ctaText && (
              <Button
                asChild
                style={{ 
                  backgroundColor: settings?.secondary_color,
                  color: '#FFFFFF',
                  '--hover-bg': `${settings?.secondary_color}E6`
                } as React.CSSProperties}
                className="hover:bg-[var(--hover-bg)] transition-colors duration-200"
              >
                <Link href="/shop">{ctaText}</Link>
              </Button>
            )}
          </div>

          {imageUrl && (
            <div className="relative h-[300px] md:h-[400px]">
              <Image
                src={imageUrl}
                alt="Hero Image"
                fill
                className="object-cover rounded-lg"
                priority
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}