// this is going to handle the testamonal carousel It will take in a partial store object and display the testamonal carousel for that store
"use client"
import { Store, Testimonial } from "@/utils/interfaces/interfaces";
import { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface TestimonialCarouselProps {
  store: Partial<Store>;
}

const TestimonialCarousel = ({ store }: TestimonialCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Updated to match the new interface
  const testimonials: Testimonial[] = store.testimonials || [];

  const nextTestimonial = () => {
    setCurrentIndex((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section style={{ backgroundColor: store.background_color }} className="py-16">
      <div className="container mx-auto px-4 max-w-5xl">
        <div className="text-center mb-12">
          <h2 
            style={{ color: store.font_color }}
            className="text-3xl md:text-4xl font-bold mb-3"
          >
            Customer Testimonials
          </h2>
          <p 
            style={{ color: store.tertiary_color }}
            className="text-lg"
          >
            What our customers say about us
          </p>
        </div>

        {testimonials.length > 0 ? (
          <div className="relative">
            <div className="overflow-hidden">
              <div className="flex items-center justify-between gap-4">
                <button
                  onClick={prevTestimonial}
                  className="bg-white rounded-full p-3 shadow-lg hover:bg-gray-100 transition-all transform hover:scale-110"
                  style={{ color: store.primary_color }}
                >
                  <ChevronLeft className="h-7 w-7" />
                </button>
                
                <div className="text-center px-4 md:px-16 py-8 max-w-3xl mx-auto">
                  {testimonials[currentIndex].image_url && (
                    <div className="mb-8">
                      <img 
                        src={testimonials[currentIndex].image_url} 
                        alt={testimonials[currentIndex].name}
                        className="w-28 h-28 rounded-full mx-auto object-cover shadow-lg border-4 border-white"
                      />
                    </div>
                  )}
                  <p 
                    className="text-xl md:text-2xl mb-8 italic leading-relaxed"
                    style={{ color: store.font_color }}
                  >
                    "{testimonials[currentIndex].testimonial}"
                  </p>
                  <p 
                    className="font-bold text-lg"
                    style={{ color: store.primary_color }}
                  >
                    {testimonials[currentIndex].name}
                  </p>
                </div>

                <button
                  onClick={nextTestimonial}
                  className="bg-white rounded-full p-3 shadow-lg hover:bg-gray-100 transition-all transform hover:scale-110"
                  style={{ color: store.primary_color }}
                >
                  <ChevronRight className="h-7 w-7" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div 
            style={{ color: store.tertiary_color }}
            className="min-h-[400px] flex items-center justify-center text-lg"
          >
            No testimonials available
          </div>
        )}
      </div>
    </section>
  );
};

export default TestimonialCarousel;
