// utils/ses.ts
import { GetIdentityVerificationAttributesCommand, SESClient, SendEmailCommand, VerifyEmailIdentityCommand } from "@aws-sdk/client-ses";

// Configure AWS SES
// AWS_ACCESS_KEY_ID
// AWS_SECRET_ACCESS_KEY
const accessKeyId = process.env.AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.AWS_SECRET_ACCESS_KEY;
const region = process.env.AWS_REGION;

if (!accessKeyId || !secretAccessKey) {
  throw new Error("AWS credentials not found in environment variables");
}

const sesClient = new SESClient({
  region: region || "us-east-1",
  credentials: {
    accessKeyId,
    secretAccessKey,
  },
});

interface EmailParams {
  to: string | string[];
  subject: string;
  body?: string;
  html?: string;
  text?: string;
  from?: string;
}

export async function sendEmail({ to, subject, body, html, text, from }: EmailParams) {
  console.log('[SES] Starting email send process');
  
  // Make sure we have a valid sender email
  const sourceEmail = from || process.env.AWS_SES_FROM_EMAIL;
  if (!sourceEmail) {
    console.error('[SES] Missing sender email address');
    throw new Error("Sender email address is required");
  }

  const recipients = Array.isArray(to) ? to : [to];
  console.log('[SES] Preparing email:', {
    from: sourceEmail,
    to: recipients,
    subject: subject || "No Subject"
  });

  const command = new SendEmailCommand({
    Source: sourceEmail,
    Destination: {
      ToAddresses: recipients,
    },
    Message: {
      Subject: {
        Data: subject || "No Subject",
        Charset: 'UTF-8'
      },
      Body: {
        Html: {
          Data: html || body || '',
          Charset: 'UTF-8'
        },
        Text: {
          Data: text || body || '',
          Charset: 'UTF-8'
        }
      }
    }
  });

  try {
    console.log('[SES] Attempting to send email...');
    const response = await sesClient.send(command);
    console.log('[SES] Email sent successfully:', response.MessageId);
    return {
      success: true,
      messageId: response.MessageId,
    };
  } catch (error) {
    console.error('[SES] Failed to send email:', {
      error: error instanceof Error ? error.message : "Unknown error occurred",
      recipients,
      subject
    });
    return {
      success: false,
      error: error instanceof Error ? error.message : "Unknown error occurred",
    };
  }
}

// lets verify email addresses so we can send emails from them. This function will accept an email address and return a boolean if it is valid.
export async function verifyEmail(email: string) {
  const command = new VerifyEmailIdentityCommand({
    EmailAddress: email,
  });
  try {
    const response = await sesClient.send(command);
    return response;
  } catch (error) {
    console.error('[SES] Failed to verify email:', { error });
    return false;
  }
}

// lets confirm that the email address is verified
export async function confirmEmail(email: string) {
  const command = new GetIdentityVerificationAttributesCommand({
    Identities: [email],
  });
  try {
    const response = await sesClient.send(command);
    return response;
  } catch (error) {
    console.error('[SES] Failed to confirm email:', { error });
    return false;
  }
}